<template>
  <v-layout>
    <v-text-field
      v-model="password"
      :label="label"
      :placeholder="placeholder"
      :append-icon="show ? 'visibility' : 'visibility_off'"
      :type="show ? 'text' : 'password'"
      @click:append="show = !show"
      outlined
      :class="['default-input-style', hasItems ? 'ml-4' : 'mx-4']"
      :rules="rules.length > 0 ? rules : passwordRules"
      :prepend-icon="icon ? 'fa-key' : ''"
      :disabled="disabled"
      :required="required"
    ></v-text-field>
    <v-layout shrink align-center :class="['mb-5', hasItems && 'mr-3']">
      <slot name="header-items"></slot>
    </v-layout>
  </v-layout>
</template>

<script>
import Validators from "../../mixins/Validators";

export default {
  mixins: [Validators],
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    rules: {
      type: Array,
      default: () => [],
      required: false,
    },
    icon: {
      type: Boolean,
      default: true,
      required: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    password: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    hasItems() {
      return !!this.$slots["header-items"];
    },
  },
};
</script>

